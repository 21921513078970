import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslocoModule } from "@ngneat/transloco";
import { FooterComponent } from "./footer.component";

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [FooterComponent],
})
export class FooterModule {}
