import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { User, UserRoles } from "./../../models/User";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy {
  @Input() isAdmin = false;
  @Input() isManager = false;
  @Input() isClient = false;
  @Input() user: User;
  @Input() userAdmin: User;
  @Input() alertsCount = 0;

  @Output() disconnect: EventEmitter<void> = new EventEmitter();
  @Output() disconnectAdmin: EventEmitter<void> = new EventEmitter();

  destroy$ = new Subject();
  userRight = UserRoles;
  alertsCount$: Observable<number>;
  isLoading = false;

  constructor(private router: Router, private cd: ChangeDetectorRef) {
    router.events
      .pipe(
        takeUntil(this.destroy$),
        tap(event => this._loadingProgress(event)),
        tap(() => this.cd.detectChanges())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onDisconnect(): void {
    if (this.userAdmin) {
      this.disconnectAdmin.emit();
    } else {
      this.disconnect.emit();
    }
  }

  /**
   * Show progress bar loading
   */
  private _loadingProgress(event: any): void {
    switch (true) {
      case event instanceof NavigationStart: {
        this.isLoading = true;
        break;
      }
      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {
        this.isLoading = false;
        break;
      }
      default: {
        break;
      }
    }
  }
}
