import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthComponent } from "./auth.component";
import { LoginComponent } from "./login/login.component";
import { PasswordLostComponent } from "./password-lost/password-lost.component";
import { LoginGuard } from "../../guards/login-guard.service";
import { ActivateAccountComponent } from "./activate-account/activate-account.component";
import { AuthGuard } from "../../guards/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "login",
      },
      {
        path: "login",
        component: LoginComponent,
        canActivate: [LoginGuard],
      },
      {
        path: "pwd-lost",
        component: PasswordLostComponent,
      },
      {
        path: "activate",
        canActivate: [AuthGuard],
        component: ActivateAccountComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
