<div class="auth {{ classAuth }}">
  <div class="block-auth">
    <div class="logo">
      <img src="/assets/images/logo_my_sporting_white.png" alt="Logo Sporting" />
    </div>

    <div>
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer [onlyBottom]="true"></app-footer>
</div>
