import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogConfirmationComponent } from "./dialog-confirmation.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { DialogConfirmationService } from "./dialog-confirmation.service";

@NgModule({
    declarations: [DialogConfirmationComponent],
    imports: [CommonModule, MatDialogModule, MatButtonModule],
    exports: [DialogConfirmationComponent, MatDialogModule],
    providers: [DialogConfirmationService]
})
export class DialogConfirmationModule {}
