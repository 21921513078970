import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserRoles, User } from "../models/User";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private http: HttpClient) {}

  // ---------------- METHODES ADMINISTRATEUR -----------------------------

  /**
   * Get user
   */
  getByAdmin$(id: number): Observable<User> {
    return this.http.get<User>(`${environment.api}/admin/user/${id}`);
  }

  /**
   * Create new user
   */
  createByAdmin$(user: Partial<User>, partnerId: number, role: UserRoles): Observable<User> {
    let apiUser = "client";
    if (role === UserRoles.DEALER) {
      apiUser = "dealer";
    } else if (role === UserRoles.MANAGER) {
      apiUser = "manager";
    } else if (role === UserRoles.ADMIN) {
      apiUser = "admin";
    } else if (role === UserRoles.SUPER_ADMIN) {
      apiUser = "superadmin";
    }
    return this.http.post<User>(`${environment.api}/admin/user/${apiUser}`, { ...user, partner: partnerId });
  }

  /**
   * Update a user
   */
  updateByAdmin$(user: Partial<User>): Observable<User> {
    return this.http.put<User>(`${environment.api}/admin/user/${user.id}`, { ...user });
  }

  /**
   * Enable user
   */
  enableByAdmin$(id: number): Observable<User> {
    return this.http.put<User>(`${environment.api}/admin/user/${id}/enable`, {});
  }

  /**
   * Disable user
   */
  disableByAdmin$(id: number): Observable<User> {
    return this.http.put<User>(`${environment.api}/admin/user/${id}/disable`, {});
  }

  /**
   * Delete user
   */
  deleteByAdmin$(id: number): Observable<any> {
    return this.http.delete(`${environment.api}/admin/user/${id}`);
  }

  // ---------------- METHODES MANAGER -----------------------------

  /**
   * Get all users of partner
   */
  getAllManagersByPartner$(): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${environment.api}/partner/user`);
  }

  /**
   * Get user
   */
  getManager$(id: number): Observable<User> {
    return this.http.get<User>(`${environment.api}/partner/user/${id}`);
  }

  /**
   * Create new user
   */
  createManagerByPartner$(user: Partial<User>, partnerId: number): Observable<User> {
    return this.http.post<User>(`${environment.api}/partner/user`, { ...user, partner: partnerId });
  }

  /**
   * Update a user
   */
  updateManagerByPartner$(user: Partial<User>): Observable<User> {
    return this.http.put<User>(`${environment.api}/partner/user/${user.id}`, { ...user });
  }

  /**
   * Enable user
   */
  enableManagerByPartner$(id: number): Observable<User> {
    return this.http.put<User>(`${environment.api}/partner/user/${id}/enable`, {});
  }

  /**
   * Disable user
   */
  disableManagerByPartner$(id: number): Observable<User> {
    return this.http.put<User>(`${environment.api}/partner/user/${id}/disable`, {});
  }

  /**
   * Delete user
   */
  deleteManagerByPartner$(id: number): Observable<any> {
    return this.http.delete(`${environment.api}/partner/user/${id}`);
  }

  /**
   * Se connecter en tant que quelqu'un d'autre. Utilisable aussi bien par les admins que les managers
   */
  logAs$(id: number): Observable<any> {
    return this.http.get(`${environment.api}/profile/${id}/impersonate`);
  }
}
