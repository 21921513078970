import { Injectable } from "@angular/core";
import { Action, createSelector, Selector, State, StateContext, Store } from "@ngxs/store";
import { compose, insertItem, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { Observable } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { Favorite } from "../models/Favorite";
import { FilterProgram } from "../models/FilterProgram";
import { Lot } from "../models/Lot";
import { Partner } from "../models/Partner";
import { Program } from "../models/Program";
import { FavoritesService } from "../services/favorites.service";
import { LotsService } from "../services/lots.service";
import { ProgramsService } from "../services/programs.service";
import { DecrementFavorites, IncrementFavorites } from "./programs.state";

export class LotsStateModel {
  lots: Lot[];
}

export class FeedLotsByProgram {
  static readonly type = "[Lots] Add lots";
  constructor(public program: Program, public isAdmin: boolean, public filters: Partial<FilterProgram>) {}
}

export class Allots {
  static readonly type = "[Lots] Allots lots with a partner";
  constructor(public lotsAlloted: Lot[], public partner?: Partner) {}
}

export class Associate {
  static readonly type = "[Lots] Associate lots with a partners";
  constructor(public lotsAlloted: Lot[], public partners: Partner[]) {}
}

export class Dissociate {
  static readonly type = "[Lots] Dissociate lots with a partner";
  constructor(public lotsAlloted: Lot[], public partners: Partner[]) {}
}

export class Boost {
  static readonly type = "[Lot] Boost a lot";
  constructor(public id: number) {}
}

export class UpdateDateDelivery {
  static readonly type = "[Lot] Update delivery date on a lot";
  constructor(public id: number, public dateDelivery: Date) {}
}

export class AddLabelsFavorite {
  static readonly type = "[Lot] Add labels favorite on lot";
  constructor(public program: Program, public lot: Lot, public labels: string) {}
}

export class RemoveFavoriteInLot {
  static readonly type = "[Lot] Remove favorite on lot";
  constructor(public program: Program, public lot: Lot, public idFavorite: number) {}
}

@State<LotsStateModel>({
  name: "lots",
  defaults: {
    lots: [],
  },
})
@Injectable()
export class LotsState {
  @Selector()
  static lots(state: LotsStateModel): Lot[] {
    return state.lots;
  }

  static lotsByProgramId(id: number): any {
    return createSelector([LotsState], (state: LotsStateModel): Lot[] => {
      return state.lots?.filter(lot => lot.program_id === id);
    });
  }

  static lotsBoostedByProgramId(id: number): any {
    return createSelector([LotsState], (state: LotsStateModel): Lot[] => {
      return state.lots?.filter((lot: Lot) => lot.program_id === id && lot.boost);
    });
  }

  static lotsWithFavoriteByProgramId(id: number): any {
    return createSelector([LotsState], (state: LotsStateModel): Lot[] => {
      return state.lots?.filter((lot: Lot) => lot.program_id === id && !!lot.favorites?.length);
    });
  }

  constructor(
    private store: Store,
    private programsService: ProgramsService,
    private lotsService: LotsService,
    private favoritesService: FavoritesService
  ) {}

  @Action(FeedLotsByProgram)
  feedLots(ctx: StateContext<LotsStateModel>, { program, isAdmin, filters }: FeedLotsByProgram): Observable<any> {
    const route = isAdmin
      ? this.programsService.searchByAdmin$(filters, true, program.id)
      : this.programsService.search$(filters, true, program.id);
    return route.pipe(
      map(programs => programs[0]?.lots),
      filter(lots => !!lots),
      tap((lots: Lot[]) => {
        const removes = ctx
          .getState()
          .lots.filter(l => l.program_id === program.id)
          .map(l => removeItem<Lot>(lot => l.id === lot.id));
        const insert = lots.reverse().map(l => {
          l.program_id = program.id;
          return insertItem<Lot>(l);
        });
        ctx.setState(
          patch({
            lots: compose(...removes, ...insert),
          })
        );
      })
    );
  }

  @Action(Allots)
  allots(ctx: StateContext<LotsStateModel>, { lotsAlloted, partner }: Allots): Observable<Response> {
    return this.lotsService.allotByAdmin$(lotsAlloted, partner).pipe(
      tap(() => {
        const update = lotsAlloted.map(l => updateItem<Lot>(lot => lot.id === l.id, { ...l, allotedPartner: partner }));
        ctx.setState(
          patch({
            lots: compose(...update),
          })
        );
      })
    );
  }

  @Action(Associate)
  associate(ctx: StateContext<LotsStateModel>, { lotsAlloted, partners }: Associate): Observable<Response> {
    return this.lotsService.associateByAdmin$(lotsAlloted, partners).pipe(
      tap(() => {
        const update = lotsAlloted.map(l => {
          const newPartners = Object.assign([], l.assignedPartners);
          partners.forEach(p => {
            if (!newPartners.some(newPartner => newPartner.id === p.id)) {
              newPartners.push(p);
            }
          });
          return updateItem<Lot>(lot => lot.id === l.id, { ...l, assignedPartners: newPartners });
        });
        ctx.setState(
          patch({
            lots: compose(...update),
          })
        );
      })
    );
  }

  @Action(Dissociate)
  dissociate(ctx: StateContext<LotsStateModel>, { lotsAlloted, partners }: Dissociate): Observable<Response> {
    return this.lotsService.dissociateByAdmin$(lotsAlloted, partners).pipe(
      tap(() => {
        const update = lotsAlloted.map(l => {
          return updateItem<Lot>(lot => lot.id === l.id, {
            ...l,
            assignedPartners: l.assignedPartners.filter(p1 => !partners.some(p2 => p2.id === p1.id)),
          });
        });
        ctx.setState(
          patch({
            lots: compose(...update),
          })
        );
      })
    );
  }

  @Action(Boost)
  boost(ctx: StateContext<LotsStateModel>, { id }: Boost): Observable<Lot> {
    return this.lotsService.boostByAdmin$(id).pipe(
      tap((lotUpdated: Lot) => {
        const index = ctx.getState().lots.findIndex(l => l.id === id);
        if (index > -1) {
          ctx.setState(
            patch({
              lots: updateItem<Lot>(index, { ...lotUpdated, program_id: ctx.getState().lots[index].program_id }),
            })
          );
        }
      })
    );
  }

  @Action(UpdateDateDelivery)
  updateDateDelivery(ctx: StateContext<LotsStateModel>, { id, dateDelivery }: UpdateDateDelivery): Observable<Lot> {
    return this.lotsService.updateDateDeliveryByAdmin$(id, dateDelivery).pipe(
      tap((lotUpdated: Lot) => {
        const index = ctx.getState().lots.findIndex(l => l.id === id);
        if (index > -1) {
          ctx.setState(
            patch({
              lots: updateItem<Lot>(index, {
                ...lotUpdated,
                dateDelivery: dateDelivery,
              })
            })
          );
        }
      })
    );
  }

  @Action(AddLabelsFavorite)
  addLabelsFavorite(
    ctx: StateContext<LotsStateModel>,
    { program, lot, labels }: AddLabelsFavorite
  ): Observable<Favorite> {
    return this.favoritesService.addLabelByPartner$(lot, labels).pipe(
      map(favorite => {
        const index = ctx.getState().lots.findIndex(l => l.id === lot.id);
        const oldFavorites = ctx.getState().lots[index].favorites;
        if (index > -1) {
          ctx.setState(
            patch({
              lots: updateItem<Lot>(index, {
                ...lot,
                program_id: ctx.getState().lots[index].program_id,
                favorites: [favorite],
              }),
            })
          );
        }
        return oldFavorites;
      }),
      filter(oldFavorites => !oldFavorites?.length),
      switchMap(() => this.store.dispatch(new IncrementFavorites(program)))
    );
  }

  @Action(RemoveFavoriteInLot)
  removeFavorite(
    ctx: StateContext<LotsStateModel>,
    { program, lot, idFavorite }: RemoveFavoriteInLot
  ): Observable<any> {
    return this.favoritesService.removeByPartner$(idFavorite).pipe(
      tap(() => {
        const index = ctx.getState().lots.findIndex(l => l.id === lot.id);
        if (index > -1) {
          ctx.setState(
            patch({
              lots: updateItem<Lot>(index, {
                ...lot,
                program_id: ctx.getState().lots[index].program_id,
                favorites: [],
              }),
            })
          );
        }
      }),
      switchMap(() => this.store.dispatch(new DecrementFavorites(program)))
    );
  }
}
