import { Partner } from "./Partner";

export enum UserRoles {
  CLIENT = "ROLE_CLIENT",
  DEALER = "ROLE_DEALER",
  MANAGER = "ROLE_MANAGER",
  ADMIN = "ROLE_ADMIN",
  SUPER_ADMIN = "ROLE_SUPER_ADMIN",
}

export enum Civility {
  M = "m",
  F = "f",
}

export enum CivilityOrEntreprise {
  M = "m",
  F = "f",
  E = "e",
}

export interface User {
  readonly id?: number;
  createdAt?: string;
  email: string;
  password?: string;
  password2?: string;
  roles: UserRoles[];

  // User details
  address?: string;
  alertSms?: boolean;
  alertEmail?: boolean;
  civility?: CivilityOrEntreprise;
  phone?: string;
  firstname?: string;
  lastname?: string;
  active?: boolean;
  partner?: Partner;
  temporaryPassword?: boolean;
  valid?: boolean;
  lastLogin?: string;
}
