<form *transloco="let t; read: 'login'" [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-error *ngIf="form.invalid && form.touched"> Des erreurs vous empêchent de valider le formulaire </mat-error>
  <mat-error *ngIf="serverMsgError">
    {{ serverMsgError }}
  </mat-error>
  <mat-form-field appearance="outline" class="input-theme-dark">
    <input id="login-new" matInput type="text" [value]="email" disabled />
  </mat-form-field>
  <mat-form-field appearance="outline" class="input-theme-dark">
    <input id="password-new" matInput type="password" placeholder="Mot de passe" formControlName="password" required />
    <mat-error *ngFor="let validation of accountValidationMessages.password">
      <mat-error
        class="error-message"
        *ngIf="
          form.get('password').hasError(validation.type) && (form.get('password').dirty || form.get('password').touched)
        "
      >
        {{ validation.message }}</mat-error
      >
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="input-theme-dark">
    <input
      id="password-new-check"
      matInput
      type="password"
      placeholder="Mot de passe vérification"
      formControlName="password2"
      required
    />
    <mat-error *ngFor="let validation of accountValidationMessages.password2">
      <mat-error
        class="error-message"
        *ngIf="
          form.get('password2').hasError(validation.type) &&
          (form.get('password2').dirty || form.get('password2').touched)
        "
      >
        {{ validation.message }}</mat-error
      >
    </mat-error>
  </mat-form-field>
  <button mat-raised-button color="primary" [disabled]="isLoading || form.invalid" [class.is-loading]="isLoading">
    Je crée mon mot de passe
  </button>
</form>
