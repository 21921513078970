import { UserRoles } from "../models/User";

export function redirectByRight(roles: UserRoles[], temporary: boolean): string {
  if (temporary) {
    return "/activate";
  }

  if (!!roles?.length) {
    if (roles.includes(UserRoles.ADMIN)) {
      return "/admin";
    } else if (roles.includes(UserRoles.MANAGER)) {
      return "/manager";
    } else if (roles.includes(UserRoles.DEALER)) {
      return "/dealer";
    } else if (roles.includes(UserRoles.CLIENT)) {
      return "/client";
    }
  }
  return "";
}
