import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-subheader",
  templateUrl: "./subheader.component.html",
  styleUrls: ["./subheader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubheaderComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() accent = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this._updateColor();
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.title && !change.title.firstChange && change.title.currentValue !== change.title.previousValue) {
      this._updateColor();
    }
  }

  private _updateColor(): void {
    this.accent = this.document.body.classList.contains("sp-theme-accent");
  }
}
