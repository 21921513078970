import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LinkFloatComponent } from "./link-float.component";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  declarations: [LinkFloatComponent],
  imports: [CommonModule, RouterModule, TranslocoModule, MatButtonModule],
  exports: [LinkFloatComponent],
})
export class LinkFloatModule {}
