import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogDocusignTokenComponent } from "./dialog-docusign-token.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { DialogDocusignTokenService } from "./dialog-docusign-token.service";

@NgModule({
    declarations: [DialogDocusignTokenComponent],
    imports: [CommonModule, MatDialogModule, MatButtonModule, RouterModule],
    providers: [DialogDocusignTokenService]
})
export class DialogDocusignTokenModule {}
