import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslocoService } from "@ngneat/transloco";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule } from "@ngxs/store";
import { ToastrModule } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./shared/components/auth/auth.module";
import { DialogConfirmationModule } from "./shared/components/dialog-confirmation/dialog-confirmation.module";
import { DialogDocusignTokenModule } from "./shared/components/dialog-docusign-token/dialog-docusign-token.module";
import { LayoutLoggedModule } from "./shared/components/layout-logged/layout-logged.module";
import { TokenInterceptor } from "./shared/interceptors/token.interceptor";
import { SharedModule } from "./shared/shared.module";
import { AuthState } from "./shared/states/auth.state";
import { LotsState } from "./shared/states/lots.state";
import { ProgramState } from "./shared/states/program.state";
import { ProgramsState } from "./shared/states/programs.state";
import { SettingsState } from "./shared/states/settings.state";
import { TranslocoRootModule } from "./transloco/transloco-root.module";
import { provideCacheableAnimationLoader, provideLottieOptions } from "ngx-lottie";
import player from "lottie-web";
registerLocaleData(localeFr);

// FIXME : A revoir quand il faudra switcher de langueS
export function preloadLang(transloco: TranslocoService): any {
  return () => transloco.load("fr").toPromise();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AuthState, SettingsState, ProgramsState, ProgramState, LotsState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      key: [
        "auth.token",
        "auth.refreshToken",
        "auth.user",
        "auth.tokenAdmin",
        "auth.refreshTokenAdmin",
        "auth.userAdmin",
        "settings",
        "programs.filters",
        "programsAdmin.filters",
      ],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    SharedModule,
    HttpClientModule,
    TranslocoRootModule,
    AuthModule,
    LayoutLoggedModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    DialogConfirmationModule,
    DialogDocusignTokenModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadLang,
      deps: [TranslocoService],
    },
    provideLottieOptions({  player: () => player }),
    provideCacheableAnimationLoader(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
