<form *transloco="let t; read: 'login'" [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline" class="input-theme-dark">
    <input matInput [placeholder]="'login.form.email' | transloco" formControlName="email" required [errorStateMatcher]="matcher" />
    <mat-error *ngFor="let validation of accountValidationMessages.email">
      <mat-error
        *ngIf="form.get('email').hasError(validation.type) && (form.get('email').dirty || form.get('email').touched)"
      >
        {{ validation.message }}
      </mat-error
      >
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="input-theme-dark">
      <input
        matInput
        type="password"
        [placeholder]="'login.form.password' | transloco"
        formControlName="password"
        required
      />
    <mat-error *ngFor="let validation of accountValidationMessages.password">
      <mat-error class="mat-error"
        *ngIf="
          form.get('password').hasError(validation.type) && (form.get('password').dirty || form.get('password').touched)
        "
      >
        {{ validation.message }}
      </mat-error
      >
    </mat-error>
  </mat-form-field>
  <button mat-raised-button color="primary" [class.is-loading]="isLoading" [disabled]="isLoading">
    {{ t("submit") }}
  </button>

  <div class="text-white text-center text-label">{{ t("forgot-message") }}</div>

  <a [routerLink]="['/pwd-lost']">{{ t("forgot") }}</a>
  <mat-error *ngIf="serverMsgError">
    {{ serverMsgError }}
  </mat-error>
</form>
