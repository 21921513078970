import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslocoModule } from "@ngneat/transloco";
import { SubheaderComponent } from "./subheader.component";

@NgModule({
  declarations: [SubheaderComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [SubheaderComponent],
})
export class SubheaderModule {}
