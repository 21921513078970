import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { take, tap } from "rxjs/operators";
import { redirectByRight } from "src/app/shared/utils/utils-redirect";
import { User } from "../../../models/User";
import { Signin } from "../../../states/auth.state";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  accountValidationMessages = {
    email: [
      { type: "required", message: "Veuillez saisir votre email" },
      { type: "email", message: "Le format ne correspond pas à un mail valide" }
    ],
    password: [{ type: "required", message: "Veuillez saisir votre mot de passe" }]
  };
  serverMsgError = "";
  form: UntypedFormGroup;
  isLoading = false;
  isClient = false;

  constructor(private fb: UntypedFormBuilder, private store: Store, private router: Router, private cd: ChangeDetectorRef) {
  }


  ngOnInit(): void {
    this.form = this.fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.required]
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const user: User = { ...this.form.value };
      this.store
        .dispatch(new Signin(user.email, user.password))
        .pipe(
          take(1),
          tap(state =>
            this.router.navigateByUrl(redirectByRight(state.auth.user.roles, state.auth.user.temporaryPassword))
          )
        )
        .subscribe(
          () => {
            this.isLoading = false;
            this.cd.detectChanges();
          },
          () => {
            this.serverMsgError = `Un problème est survenu lors de l'authentification`;
            this.isLoading = false;
            this.cd.detectChanges();
          }
        );
    }
  }
}
