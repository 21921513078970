import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Favorite } from "../models/Favorite";
import { Lot } from "../models/Lot";

@Injectable({
  providedIn: "root",
})
export class FavoritesService {
  constructor(private http: HttpClient) {}

  /**
   * Get all favorites for partner
   */
  getAllByPartner$(): Observable<Favorite[]> {
    return this.http.get<Favorite[]>(`${environment.api}/partner/favorite`);
  }

  /**
   * Get all labels uniques in favorites
   */
  getAllLabels$(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api}/partner/favorite/labels`);
  }

  /**
   * Create a new favorite
   */
  addLabelByPartner$(lot: Lot, label: string): Observable<Favorite> {
    return this.http.post<Favorite>(`${environment.api}/partner/favorite`, {
      lot: lot.id,
      label,
    });
  }

  /**
   * Create a new favorite
   */
  removeByPartner$(id: number): Observable<Response> {
    return this.http.delete<Response>(`${environment.api}/partner/favorite/${id}`);
  }
}
