<div class="container">
  <!-- Menu tablet / desktop -->
  <div class="row d-none d-sm-block">
    <mat-toolbar>
      <a [routerLink]="'/dealer/home'">
        <img src="/assets/images/logo_my_sporting_white.png" href="/"/>
      </a>
      <ng-container *ngTemplateOutlet="menuList; context: { mobile: false }" menuList></ng-container>
    </mat-toolbar>
  </div>

  <!-- Menu mobile -->
  <div class="row d-block d-sm-none">
    <app-header-mobile>
      <ng-container *ngTemplateOutlet="profile" profile></ng-container>
      <ng-container *ngTemplateOutlet="menuList; context: { mobile: true }" menuList></ng-container>
      <ng-container *ngTemplateOutlet="logout" logout></ng-container>
    </app-header-mobile>
  </div>
</div>

<mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>

<!-- Menu list client / partner -->
<ng-template #menuList let-mobile="mobile">
  <div *transloco="let t; read: 'header'" class="menu-links">
    <!-- Menu client -->
    <ul *ngIf="isClient" class="menu menu-client">
      <li>
        <a routerLinkActive="active" mat-button [routerLink]="'/client/home'">
          <span class="label">Actualités</span>
          <i class="icon-home"></i>
        </a>
      </li>
      <li>
        <a routerLinkActive="active" mat-button [routerLink]="'/client/reservations'">
          <span class="label">Mes biens</span>
          <i class="icon-document"></i>
        </a>
      </li>
      <!-- <li>
        <a routerLinkActive="active" mat-button [routerLink]="'/client/profile'">
          <span class="label">Profil</span>
          <i class="icon-user"></i>
        </a>
      </li> -->
      <li *ngIf="mobile" class="menu-logout">
        <a routerLinkActive="active" mat-button (click)="onDisconnect()">
          <span class="label">{{ t("logout") }}</span>
          <i class="icon-logout"></i>
        </a>
      </li>
    </ul>

    <!-- Menu partner / manager / admin -->
    <ul *ngIf="!isClient" class="menu menu-partner">
      <li>
        <a routerLinkActive="active" mat-button [routerLink]="'/dealer/home'"> {{ t("home") }} </a>
      </li>
      <li>
        <a
          routerLinkActive="active"
          mat-button
          matBadge="{{ alertsCount > 0 ? alertsCount : '' }}"
          matBadgeColor="primary"
          [routerLink]="'/dealer/dashboard'"
        >
          {{ t("dashboard") }}
        </a>
      </li>
      <li>
        <a routerLinkActive="active" mat-button [routerLink]="'/dealer/news'"> {{ t("actualities") }} </a>
      </li>
      <li>
        <a routerLinkActive="active" mat-button [routerLink]="'/dealer/oav'"> {{ t("oav") }} </a>
      </li>
      <li>
        <a routerLinkActive="active" mat-button target="_blank" href="https://www.sporting-promotion.fr/faq-immobilier-neuf/"> {{ t("faq") }} </a>
      </li>
      <li>
        <a routerLinkActive="active" *ngIf="isManager" mat-button [routerLink]="'/manager'"> {{ t("admin") }} </a>
      </li>
      <li>
        <a routerLinkActive="active" *ngIf="isAdmin" mat-button [routerLink]="'/admin'"> {{ t("sporting") }} </a>
      </li>
      <li *ngIf="mobile" class="menu-logout">
        <a routerLinkActive="active" mat-button (click)="onDisconnect()">
          <span class="label">{{ t("logout") }}</span>
          <i class="icon-logout"></i>
        </a>
      </li>
      <li class="user-data" *ngIf="!!user && !mobile">
        <div>
          {{ userAdmin ? userAdmin.firstname + " " + userAdmin.lastname : user.firstname + " " + user.lastname }}
        </div>
        <div *ngIf="userAdmin">Connecté en tant que {{ user.firstname }} {{ user.lastname }}</div>
      </li>
    </ul>

    <!-- Profile / Logout -->
    <div *ngIf="!mobile" class="menu-user">
      <ng-container *ngTemplateOutlet="profile"></ng-container>
      <ng-container *ngTemplateOutlet="logout"></ng-container>
    </div>
  </div>
</ng-template>

<!-- Button profile -->
<ng-template #profile>
  <a
    class="user-access"
    *ngIf="!userAdmin"
    routerLinkActive="active"
    [routerLink]="'/account'"
    mat-fab extended
    [matTooltip]="'header.profile' | transloco"
    matTooltipPosition="below"
    aria-label=""
    color="primary"
  >
    <i class="icon-user"></i>
  </a>
</ng-template>

<!-- Button logout -->
<ng-template #logout>
  <a
    (click)="onDisconnect()"
    mat-fab extended
    class="user-logout"
    color="accent"
    [matTooltip]="
      userAdmin
        ? 'Se reconnecter en tant que ' + userAdmin.firstname + ' ' + userAdmin.lastname
        : ('header.logout' | transloco)
    "
    matTooltipPosition="below"
    aria-label=""
  >
    <i class="icon-logout"></i>
  </a>
</ng-template>
