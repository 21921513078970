import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { DialogConfirmationComponent } from "./dialog-confirmation.component";
import { IDialogConfirmation } from "./dialog-confirmation.model";

@Injectable()
export class DialogConfirmationService {
  constructor(private dialog: MatDialog) {}

  openModal$(data: IDialogConfirmation): Observable<boolean | null> {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data,
    });
    return dialogRef.afterClosed();
  }
}
