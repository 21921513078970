import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "../models/User";

export interface GetLotData {
  favorite?: number;
  boosted?: number;
}

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  /**
   * Get profile user logged
   */
  get$(): Observable<Partial<User>> {
    return this.http.get<Partial<User>>(`${environment.api}/profile`);
  }

  /**
   * Update profile user logged
   */
  update$(user: Partial<User>): Observable<Partial<User>> {
    return this.http.post<Partial<User>>(`${environment.api}/profile`, { ...user });
  }

  /**
   * Update password user logged
   */
  updatePassword$(plainPassword: string): Observable<any> {
    return this.http.post(`${environment.api}/profile/password`, { plainPassword });
  }

  /**
   * Get data related to the user's own lot scope : favoritesCount, boostedCount
   */
  getLotData$(): Observable<GetLotData> {
    return this.http.get<GetLotData>(`${environment.api}/profile/lot-data`);
  }

  /**
   * Remove profile
   */
  delete$(): Observable<Response> {
    return this.http.delete<Response>(`${environment.api}/profile/delete`);
  }
}
