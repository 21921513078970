import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() contacts: { title: string; name: string; tel: string; email: string }[] = [];
  @Input() onlyBottom = false;

  constructor() {}
}
