<div *transloco="let t">
  <form *ngIf="!formValidated" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2>{{ t("passwordLost.title") }}</h2>

    <mat-form-field appearance="outline" class="input-theme-dark">
      <input matInput [placeholder]="'login.form.email' | transloco" formControlName="email" required />
      <mat-error *ngFor="let validation of accountValidationMessages.email">
        <mat-error
          class="error-message"
          *ngIf="form.get('email').hasError(validation.type) && (form.get('email').dirty || form.get('email').touched)"
        >
          {{ t(validation.message) }}
        </mat-error>
      </mat-error>
    </mat-form-field>
    <button mat-raised-button [disabled]="form.invalid || isLoading" [class.is-loading]="isLoading" color="primary">
      {{ t("global.send") }}
    </button>

    <mat-error *ngIf="form.invalid && form.touched">{{ t("global.errors.global") }}</mat-error>
    <mat-error *ngIf="notFoundError">Il n'existe pas de compte associé à l'email fourni.</mat-error>
    <mat-error *ngIf="unknownError">Une erreur inconnue est survenue. Merci de réessayer.</mat-error>
  </form>
  <p *ngIf="formValidated" class="email-sent">
    {{ t("passwordLost.emailSent") }}
  </p>
  <div class="links">
    <a [routerLink]="['/login']">{{ t("login.login") }}</a>
  </div>
</div>
