import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Alert } from "../models/Alert";
import { BaseFilters, PaginationDatas } from "../models/Pagination";

@Injectable({
  providedIn: "root",
})
export class AlertsService {
  constructor(private http: HttpClient) {}

  /**
   * Get all actions in lot by admin
   */
  getAllByPartner$(filters?: BaseFilters): Observable<PaginationDatas<Alert>> {
    const params = this._paramsFilters(filters);
    return this.http.get<PaginationDatas<Alert>>(`${environment.api}/alert`, { params });
  }

  /**
   * Toggle read alert for multiple alerts
   */
  read$(ids?: number[]): Observable<Response> {
    if (!!ids?.length) {
      return this.http.post<Response>(`${environment.api}/alert/read`, { alerts: ids });
    }
    return this.http.post<Response>(`${environment.api}/alert/read`, {});
  }

  /**
   * Get count alerts not read
   */
  count$(): Observable<number> {
    return this.http.get<number>(`${environment.api}/alert/count`);
  }

  /**
   * Create list filters query params
   */
  private _paramsFilters(filters: BaseFilters): HttpParams {
    const page = filters.page?.toString() ?? "1";
    const limit = filters.limit?.toString() ?? "10";
    let params = new HttpParams().append("page", page).append("limit", limit);
    if (!!filters.sort && !!filters.sort.name && !!filters.sort.direction) {
      params = params.append("sort", filters.sort.name);
      params = params.append("direction", filters.sort.direction);
    }
    return params;
  }
}
