import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { take, tap } from "rxjs/operators";
import { AuthState } from "src/app/shared/states/auth.state";
import { User } from "../../../models/User";
import { ProfileService } from "../../../services/profile.service";

@Component({
  selector: "app-activate-account",
  templateUrl: "./activate-account.component.html",
  styleUrls: ["./activate-account.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateAccountComponent implements OnInit {
  form: UntypedFormGroup;
  email = this.store.selectSnapshot(AuthState.user)?.email;
  isLoading = false;
  accountValidationMessages = {
    password: [
      { type: "required", message: "Veuillez saisir votre mot de passe" },
      {
        type: "pattern",
        message: "Le mot de passe doit contenir au moins 8 caractères, 1 majuscule, 1 caractère spécial",
      },
    ],
    password2: [
      { type: "required", message: "Veuillez saisir votre mot de passe" },
      { type: "mustMatch", message: "Le mot de passe est différent" },
    ],
  };

  serverMsgError = "";

  constructor(
    private router: Router,
    private store: Store,
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        password: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"),
          ]),
        ],
        password2: [""],
      },
      {
        validator: this._mustMatch("password", "password2"),
      }
    );
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const u: User = { ...this.form.value };
      this.profileService
        .updatePassword$(this.form.get("password").value)
        .pipe(take(1))
        .subscribe(
          () => this.router.navigateByUrl("login"),
          () => {
            this.serverMsgError = "Un problème est survenu, veuillez vous reconnecter plus tard";
            this.isLoading = false;
            this.cd.detectChanges();
          }
        );
    }
  }

  private _mustMatch(controlName: string, matchingControlName: string): (formGroup: UntypedFormGroup) => any {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
