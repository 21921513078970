import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, skip, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-header-mobile",
  templateUrl: "./header-mobile.component.html",
  styleUrls: ["./header-mobile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileComponent implements OnDestroy {
  destroy$ = new Subject();
  isOpen = false;

  @Output() logout: EventEmitter<void> = new EventEmitter();

  constructor(private router: Router, private cd: ChangeDetectorRef) {
    router.events
      .pipe(
        takeUntil(this.destroy$),
        skip(1),
        filter(event => event instanceof NavigationEnd && this.isOpen),
        tap(() => (this.isOpen = false)),
        tap(() => this.cd.detectChanges())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
