import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-link-float",
  templateUrl: "./link-float.component.html",
  styleUrls: ["./link-float.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkFloatComponent {
  @Input() route: string;
  @Input() icon = "icon-plus";
  @Input() label: string;
  @Input() isPhone = false;

  constructor() {}
}
