<h2 mat-dialog-title align="center">Une action est requise dans Docusign</h2>
<mat-dialog-content align="center">
  <p>
    Afin de pouvoir continuer à utiliser le service de gestion de signature électronique, nous vous invitons à vous
    munir des identifiants Docusign et cliquer sur le bouton suivant. Si vous n’avez pas ces identifiants merci de
    contacter votre administrateur.
  </p>
  <a mat-raised-button [href]="route$ | async" target="_blank" color="primary">Ouvrir DocuSign</a>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="third">Fermer</button>
</mat-dialog-actions>
