import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DialogDocusignTokenComponent } from "./dialog-docusign-token.component";

@Injectable()
export class DialogDocusignTokenService {
  dialogRefDocusign: MatDialogRef<DialogDocusignTokenComponent>;

  constructor(private http: HttpClient, private dialog: MatDialog) {}

  openModal$(): Observable<boolean | null> {
    if (!this.dialogRefDocusign) {
      this.dialogRefDocusign = this.dialog.open(DialogDocusignTokenComponent, {
        disableClose: true,
      });
    }
    return this.dialogRefDocusign.afterClosed().pipe(
      take(1),
      tap(() => (this.dialogRefDocusign = null))
    );
  }
}
