export const environment = {
  production: true,
  api: "/api",
  alentoorWidgetId: "d6c2bd669779b551eba9ab7f611ad482",
  contactDealer: {
    title: "Contact vendeur",
    name: "Lise Soulignac",
    tel: "06 07 22 70 12",
    email: "l.soulignac@sporting-promotion.fr",
  },
  contactClient: {
    title: "Contact client",
    name: "Célia Bresson",
    tel: "05 34 40 50 98",
    email: "c.bresson@sporting-promotion.fr",
  },
};
