import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";
import { DialogDocusignTokenService } from "./dialog-docusign-token.service";

@Component({
  selector: "app-dialog-docusign-token",
  templateUrl: "./dialog-docusign-token.component.html",
  styleUrls: ["./dialog-docusign-token.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDocusignTokenComponent {
  route$ = this.authService.getRouteDocuSign$();

  constructor(public dialogRef: MatDialogRef<DialogDocusignTokenComponent>, private authService: AuthService) {}
}
