import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LayoutLoggedComponent } from "./shared/components/layout-logged/layout-logged.component";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { AuthGuard } from "./shared/guards/auth-guard.service";
import { RoleGuard } from "./shared/guards/role-guard.service";
import { UserRoles } from "./shared/models/User";

const routes: Routes = [
  {
    path: "extranet",
    data: { isClient: false },
    loadChildren: () => import("./shared/components/auth/auth.module").then(m => m.AuthModule),
  },
  {
    path: "",
    data: { isClient: true },
    loadChildren: () => import("./shared/components/auth/auth.module").then(m => m.AuthModule),
  },
  {
    path: "sso/:token",
    loadChildren: () => import("./shared/components/sso/sso.module").then(m => m.SsoModule),
  },
  { path: "not-found", component: PageNotFoundComponent },
  {
    path: "",
    component: LayoutLoggedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dealer",
      },
      {
        path: "client",
        canActivate: [RoleGuard],
        data: { role: UserRoles.CLIENT },
        loadChildren: () => import("./client/client.module").then(m => m.ClientModule),
      },
      {
        path: "dealer",
        canActivate: [RoleGuard],
        data: { role: UserRoles.DEALER },
        loadChildren: () => import("./dealer/dealer.module").then(m => m.DealerModule),
      },
      {
        path: "manager",
        canActivate: [RoleGuard],
        data: { role: UserRoles.MANAGER },
        loadChildren: () => import("./manager/manager.module").then(m => m.ManagerModule),
      },
      {
        path: "admin",
        canActivate: [RoleGuard],
        data: { role: UserRoles.ADMIN },
        loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule),
      },
      {
        path: "account",
        loadChildren: () => import("./account/account.module").then(m => m.AccountModule),
      },
    ],
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login",
  },
  {
    path: "**",
    redirectTo: "/not-found",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
