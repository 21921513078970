import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslocoModule } from "@ngneat/transloco";
import { HeaderComponent } from "./header.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { HeaderMobileComponent } from "./header-mobile/header-mobile.component";

@NgModule({
  declarations: [HeaderComponent, HeaderMobileComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    MatToolbarModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
