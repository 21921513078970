import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Lot, LotDispositifsEnum } from "../models/Lot";
import { Partner } from "../models/Partner";
import { User } from "../models/User";
import { map } from "rxjs/operators";
// For dateDelivery update
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class LotsService {
  constructor(private http: HttpClient) {}

  /**
   * Get all lots in program for partner
   */
  getAllInProgramByPartner$(idProgram: number): Observable<Lot[]> {
    return this.http.get<Lot[]>(`${environment.api}/partner/program/${idProgram}/lots`);
  }

  /**
   * Get all lots in program for admin
   */
  getAllInProgramByAdmin$(idProgram: number): Observable<Lot[]> {
    return this.http.get<Lot[]>(`${environment.api}/admin/program/${idProgram}/lots`);
  }

  /**
   * Get lot by id
   */
  getByPartner$(id: number): Observable<Lot> {
    return this.http.get<Lot>(`${environment.api}/partner/lot/${id}`);
  }

  /**
   * Get lot by id
   */
  getByAdmin$(id: number): Observable<Lot> {
    return this.http.get<Lot>(`${environment.api}/admin/lot/${id}`);
  }

  /**
   * Get lot by id
   */
  getByClient$(id: number): Observable<Lot> {
    return this.http.get<Lot>(`${environment.api}/client/lot/${id}`);
  }

  /**
   * Associate multiple lots to multiple partners
   */
  associateByAdmin$(lots: Lot[], partners: Partner[]): Observable<Response> {
    return this.http.post<Response>(`${environment.api}/admin/lot/associate`, {
      lots: lots.map(lot => lot.id),
      partners: partners.map(partner => partner.id),
    });
  }

  /**
   * Disassociate multiple lots to multiple partners
   */
  dissociateByAdmin$(lots: Lot[], partners: Partner[]): Observable<Response> {
    return this.http.post<Response>(`${environment.api}/admin/lot/dissociate`, {
      lots: lots.map(lot => lot.id),
      partners: partners.map(partner => partner.id),
    });
  }

  /**
   * Allot multiple lots to a partner
   */
  allotByAdmin$(lots: Lot[], partner: Partner): Observable<Response> {
    return this.http.post<Response>(`${environment.api}/admin/lot/allot`, {
      lots: lots.map(lot => lot.id),
      partner: partner?.id ?? null,
    });
  }

  /**
   * Boost a lot by Admin
   */
  boostByAdmin$(lotId: number): Observable<Lot> {
    return this.http.post<Lot>(`${environment.api}/admin/lot/${lotId}/boost`, {});
  }

  /**
   * Update dateDelivery for a lot by Admin
   */
  updateDateDeliveryByAdmin$(lotId: number, date: Date): Observable<Lot> {
    let dateString: String = moment(date).format("DD-MM-YYYY");
    return this.http.post<Lot>(`${environment.api}/admin/lot/${lotId}/dateDelivery/${dateString}`, {});
  }

  getDealersByAdmin$(lotId: number): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${environment.api}/admin/lot/${lotId}/dealers`);
  }

  /**
   * Get values for feed filters (types, natures, ...)
   */
  getFilters$(): Observable<{
    nature: string[];
    dispositif: any[];
    type: any[];
    floor: string[];
    annexe: string[];
  }> {
    return this.http
      .get<{ nature: string[]; dispositif: any[]; type: any[]; floor: string[]; annexe: string[] }>(
        `${environment.api}/search/filters`
      )
      .pipe(
        map(data => {
          // HACK tout pourri pour ajouter manuellement un choix..
          const dispositifs = [];
          data.dispositif.forEach(dispo => {
            if (dispo !== "PINEL") {
              if (dispo === LotDispositifsEnum.LIBRE) {
                dispositifs.push({ value: dispo, label: "LIBRE / PINEL" });
              } else {
                dispositifs.push({ value: dispo, label: dispo });
              }
            }
          });
          data.dispositif = dispositifs;
          const newTypes = new Map();
          let label = "";
          data.type.forEach(type => {
            switch (true) {
              case /^T.*D/.test(type):
                label = type.replace("D", " Duplex");
                newTypes.set(label, { value: type, label: label });
                break;
              case /^T.*bis/.test(type):
                label = type.replace("bis", " Bis");
                newTypes.set(label, { value: type, label: label });
                break;
              case /(^T\d*)(\s+.*)/.test(type):
                label = type.replace(/(^T\d*)(\s+.*)/, "$1");
                newTypes.set(label, { value: label, label: label });
                break;
              case "local" === type.toLocaleLowerCase() || "bureau" === type.toLocaleLowerCase() || "commerce" === type.toLocaleLowerCase() || /^T\d+/.test(type):
                newTypes.set(type, { value: type, label: type });
                break;
              default:
                break;
            }
          });
          data.type = [...newTypes.values()];
          return data;
        })
      );
  }
}
