import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "../states/auth.state";
import { Store } from "@ngxs/store";
@Injectable({
  providedIn: "root",
})
export class LoginGuard  {
  constructor(private store: Store, private router: Router) {}

  canActivate(): boolean {
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      return this._redirectByRight();
    }
    return true;
  }

  private _redirectByRight(): boolean {
    if (this.store.selectSnapshot(AuthState.isAdmin)) {
      this.router.navigate(["/admin"]);
      return false;
    } else if (this.store.selectSnapshot(AuthState.isManager)) {
      this.router.navigate(["/manager"]);
      return false;
    } else if (this.store.selectSnapshot(AuthState.isDealer)) {
      this.router.navigate(["/dealer"]);
      return false;
    } else if (this.store.selectSnapshot(AuthState.isClient)) {
      this.router.navigate(["/client"]);
      return false;
    }
    return true;
  }
}
