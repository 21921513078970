import { Component, OnInit, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent implements OnInit {
  @Input() max = 5;
  @Input() value: number;

  stars: number[] = [];

  constructor() {}

  ngOnInit(): void {
    for (let i = 0; i < this.max; i++) {
      this.stars.push(i);
    }
  }
}
