import { DOCUMENT, Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Subject, timer } from "rxjs";
import { filter, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "../../models/User";
import { AlertsService } from "../../services/alerts.service";
import { AuthState, Signout, SignoutAs, UpdateNotificationCount } from "../../states/auth.state";
import { ResetState } from "../../states/programs.state";
import { MatTable } from "@angular/material/table";

@Component({
  selector: "app-layout-logged",
  templateUrl: "./layout-logged.component.html",
  styleUrls: ["./layout-logged.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutLoggedComponent implements OnInit, OnDestroy, AfterViewInit {
  destroy$ = new Subject();
  contacts = [];
  isAdmin$ = this.store.select(AuthState.isAdmin);
  isManager$ = this.store.select(AuthState.isManager);
  isDealer$ = this.store.select(AuthState.isDealer);
  alertsCount$ = this.store.select(AuthState.alerts);
  isClient$ = this.store
    .select(AuthState.isClient)
    .pipe(tap(isClient => (this.contacts = [isClient ? environment.contactClient : environment.contactDealer])));
  userData: User;
  userAdmin: User;
  subheaderTitle: string;
  contactPhone = environment.contactClient?.tel;

  @ViewChild("scroller") scroller: ElementRef;
  @ViewChild("pageContainer") pageContainer: ElementRef;
  private scrollTopPositions: { [url: string]: number } = {};
  private pageContainerHeights: { [url: string]: number } = {};

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd),
        map(() => this._getLeafRoute(this.route.snapshot)),
        tap(res => this._applyTheme(res.data)),
        map(res => res.data.subheader ?? null),
        tap(title => (this.subheaderTitle = title)),
        tap(title => this._classSubheaderBody(title))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    let observer: MutationObserver;
    let backForward: boolean;

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e instanceof NavigationStart || e instanceof NavigationEnd)
      )
      .subscribe({
        next: e => {
          const scrollContainer = this.scroller.nativeElement;
          const pageContainer = this.pageContainer.nativeElement;

          if (e instanceof NavigationStart) {
            pageContainer.style.minHeight = null;
            if (backForward == null) {
              const url = this.router.url.replace(/&+/, '&').replace(/&+$/, '');
              this.scrollTopPositions[url] = scrollContainer.scrollTop;
              this.pageContainerHeights[url] = this.getPageContainerHeight();
              backForward = !!e.restoredState;
            }
          } else if (e instanceof NavigationEnd) {
            const newUrl = this.router.url.replace(/&+/, '&').replace(/&+$/, '');
            setTimeout(() => {
              let scrollTop: number;
              // console.log(backForward, newUrl, this.scrollTopPositions);
              if (backForward) {
                scrollTop = this.scrollTopPositions[newUrl];
                const pageContainerHeight = this.pageContainerHeights[newUrl];

                // console.log(scrollTop);
                if (scrollTop && pageContainerHeight && this.getPageContainerHeight() < pageContainerHeight) {
                  pageContainer.style.minHeight = `${pageContainerHeight}px`;
                  observer =
                    observer ||
                    new MutationObserver(function () {
                      pageContainer.style.minHeight = null;
                      observer.disconnect();
                    });
                  observer.disconnect();
                  observer.observe(pageContainer, { childList: true, subtree: true });
                }
              }
              scrollContainer.scrollTop = scrollTop || 0;
              setTimeout(() => {
                // console.log("backForward null");
                backForward = null;
              }, 400);
            }, 0);
          }
        },
      });
  }

  private getPageContainerHeight(): number {
    return parseInt(getComputedStyle(this.pageContainer.nativeElement).getPropertyValue("height"));
  }

  ngOnInit(): void {
    this.store.select(AuthState.user).subscribe(user => {
      this.userData = user;
      this.cd.detectChanges();
    });
    this.store.select(AuthState.userAdmin).subscribe(user => {
      this.userAdmin = user;
      this.cd.detectChanges();
    });

    // Call every 30 seconds for alerts
    timer(0, 30000)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.store.dispatch(new UpdateNotificationCount()))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  back(): void {
    this.location.back();
  }

  disconnect(): void {
    this.store
      .dispatch(new Signout())
      .pipe(
        take(1),
        switchMap(() => this.store.dispatch(new ResetState())),
        tap(() => this.router.navigate(["/login"]))
      )
      .subscribe();
  }

  disconnectAdmin(): void {
    this.store
      .dispatch(new SignoutAs())
      .pipe(
        take(1),
        tap(() => {
          this.router.navigate(["/login"]);
        })
      )
      .subscribe();
  }

  private _getLeafRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (route.children.length) {
      if (route.children.length > 1) {
        console.warn("More than one child !");
      }
      route = route.children[0];
    }
    return route;
  }

  private _applyTheme(datas: any): void {
    let classToRemove = null;
    this.document.body.classList.forEach(
      classBody => (classToRemove = classBody.startsWith("sp-theme-") ? classBody : classToRemove)
    );
    if (!!classToRemove) {
      this.document.body.classList.remove(classToRemove);
    }
    if (!!datas.theme) {
      this.document.body.classList.add(`sp-theme-${datas.theme}`);
    }
  }

  private _classSubheaderBody(title: string): void {
    let classToRemove = null;
    this.document.body.classList.forEach(
      classBody => (classToRemove = classBody.startsWith("sp-subheader-") ? classBody : null)
    );
    if (!!classToRemove) {
      this.document.body.classList.remove(classToRemove);
    }
    if (!!title) {
      this.document.body.classList.add("sp-subheader-show");
    }
  }
}
