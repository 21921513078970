import { Component, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { LoadProfile } from "./shared/states/auth.state";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "sp-app";

  constructor(private store: Store) {}

  ngOnInit(): void {
    //Si le user a un token valide enregistré, on télécharge les données de son profil
    this.store.dispatch(new LoadProfile());
  }
}
