export enum FileAppTypeEnum {
  // PROGRAMME
  IMAGE = "IMAGE",
  LOGO = "LOGO",
  PLAQUETTE = "PLAQUETTE",
  DESCRIPTIF_TECHNIQUE = "DESCRIPTIF_TECHNIQUE",
  ERP = "ERP",
  PLAN_REPERAGE = "PLAN_REPERAGE",
  PLAN_MASSE = "PLAN_MASSE",
  PLAN_LOCAL = "PLAN_LOCAL",
  MARKER = "MARKER",
  CONTRAT = "CONTRAT",
  FNO = "FNO",
  PREVOYANCE = "PREVOYANCE",
  SERENITE = "SERENITE",
  PRESENTATION = "PRESENTATION",
  TARIF = "TARIF",

  // LOT
  PLAN = "PLAN",
  PLAN_PARKING = "PLAN_PARKING",

  // RESERVATION
  APPEL_FOND = "APPEL_FOND",
  CONTRAT_RESA = "CONTRAT_RESA",
  DOC_MARKETING = "DOC_MARKETING",
  ODP = "ODP",
  ATTESTATION_ACTE = "ATTESTATION_ACTE",
  SUIVI_RESERVES = "SUIVI_RESERVES",
  ATTESTATION_LEVEE_RESERVES = "ATTESTATION_LEVEE_RESERVES",
}

export enum AppelFondFileTypeEnum {
  ACHEVEMENT_DES_FONDATIONS = "ACHEVEMENT DES FONDATIONS",
  ACHEVEMENT_DU_PLANCHER_BAS_RDC = "ACHEVEMENT DU PLANCHER BAS RDC",
  HEHA = "HEHA",
  HE = "HE",
  HA = "HA",
  ACHEVEMENT_DES_TRAVAUX = "ACHEVEMENT DES TRAVAUX",
  REMISE_DES_CLES = "REMISE DES CLES",
}

export interface FileApp {
  id: number;
  type: FileAppTypeEnum;
  path: string;
  originalName: string;
  blob?: string;
}
