import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { catchError, take, tap } from "rxjs/operators";
import { AuthService } from "../../../services/auth.service";
import { throwError } from "rxjs";

@Component({
  selector: "app-password-lost",
  templateUrl: "./password-lost.component.html",
  styleUrls: ["./password-lost.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordLostComponent implements OnInit {
  form: UntypedFormGroup;
  accountValidationMessages = {
    email: [
      { type: "required", message: "global.errors.emailRequired" },
      { type: "email", message: "global.errors.emailInvalid" },
    ],
  };
  isLoading = false;
  formValidated = false;

  notFoundError = false;
  unknownError = false;

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.notFoundError = false;
      this.unknownError = false;
      this.isLoading = true;
      this.authService
        .passwordLost$(this.form.get("email").value)
        .pipe(
          take(1),
          catchError(err => {
            if (err.error && err.error.error === "Email not found") {
              this.notFoundError = true;
            } else {
              this.unknownError = true;
            }
            this.isLoading = false;
            this.cd.detectChanges();
            return throwError(err);
          }),
          tap(() => (this.isLoading = false)),
          tap(() => (this.formValidated = true)),
          tap(() => this.cd.detectChanges())
        )
        .subscribe();
    }
  }
}
