<div *ngIf="!onlyBottom" class="footer-top">
  <div class="container">
    <div *ngFor="let contact of contacts" class="block-contact contacts-size-{{ contacts.length }}">
      <span class="mr-small">{{ contact.title }}</span>
      <span class="mr-small"><div class="dot"></div></span>
<!--      <span class="contact-name mr-small">{{ contact.name }}</span>-->
      <a class="contact-phone mr-small" [href]="'tel:' + contact.tel">
        <i class="icon-phone"></i>
        <span>{{ contact.tel }}</span>
      </a>
<!--      <a class="contact-email mr-small" [href]="'mailto:' + contact.email">-->
<!--        <i class="icon-mail"></i>-->
<!--        <span>{{ contact.email }}</span>-->
<!--      </a>-->
    </div>
  </div>
</div>
<div class="footer-bottom">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6">
        <a [href]="'footer.link' | transloco" target="_blank">
          <i class="icon-website primary"></i>
          <span>{{ "footer.link" | transloco }}</span>
        </a>
      </div>
      <div class="col-12 col-sm-6">
        <a [href]="'footer.createdByLink' | transloco" target="_blank">
          <i class="icon-website primary"></i>
          <span>{{ "footer.createdBy" | transloco }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
