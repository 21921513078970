import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FileApp } from "../models/FileApp";
import { FilterProgram } from "../models/FilterProgram";
import { LotDurationsEnum } from "../models/Lot";
import { Program } from "../models/Program";

@Injectable({
  providedIn: "root",
})
export class ProgramsService {
  constructor(private http: HttpClient) {}

  /**
   * Get program for partner
   */
  getByPartner$(id: number): Observable<Program> {
    return this.http.get<Program>(`${environment.api}/partner/program/${id}`);
  }

  /**
   * Get program for client
   */
  getByClient$(id: number): Observable<Program> {
    return this.http.get<Program>(`${environment.api}/client/program/${id}`);
  }

  /**
   * Get program for admin
   */
  getByAdmin$(id: number): Observable<Program> {
    return this.http.get<Program>(`${environment.api}/admin/program/${id}`);
  }

  /**
   * Update a program
   */
  updateByAdmin$(id: number, program: Partial<Program>): Observable<Program> {
    return this.http.put<Program>(`${environment.api}/admin/program/${id}`, { ...program });
  }

  /**
   * Update a program
   */
  updateStatusByAdmin$(id: number, program: Partial<Program>): Observable<Program> {
    return this.http.put<Program>(`${environment.api}/admin/program/${id}/status`, { ...program });
  }

  /**
   * Get files in program by admin
   */
  getFilesByAdmin$(id: number): Observable<FileApp[]> {
    return this.http.get<FileApp[]>(`${environment.api}/admin/program/${id}/files`);
  }

  /**
   * Get files in program by partner
   */
  getFilesByPartner$(id: number): Observable<FileApp[]> {
    return this.http.get<FileApp[]>(`${environment.api}/partner/program/${id}/files`);
  }

  /**
   * Add files in program
   */
  addFilesByAdmin$(id: number, files: File[]): Observable<File[]> {
    const params = new FormData();
    files.forEach((file, index) => params.append(`files[${index}]`, file, file.name));
    return this.http.post<File[]>(`${environment.api}/admin/program/${id}/files`, params);
  }

  /**
   * Remove files in program
   */
  removeFiles$(id: number, idFile: number): Observable<Response> {
    return this.http.delete<Response>(`${environment.api}/admin/program/${id}/files/${idFile}`);
  }

  /**
   * Search programs
   */
  search$(filters?: Partial<FilterProgram>, includeLots = false, programId = 0): Observable<Program[]> {
    let params = this._createQueryParams(filters);
    if (includeLots) {
      params = params.set(`filter[with_lots]`, "1");
      params = params.set(`filter[with_favorites]`, "1");
      params = params.delete("filter[favorites]");
    }
    if (!!programId) {
      params = params.set(`filter[program_id]`, programId.toString());
    }
    return this.http.get<Program[]>(`${environment.api}/search`, { params });
  }

  /**
   * Search programs by admin
   */
  searchByAdmin$(filters: Partial<FilterProgram>, includeLots = false, programId?: number): Observable<Program[]> {
    let params = this._createQueryParams(filters);
    if (includeLots) {
      params = params.set(`filter[with_lots]`, "1");
    }
    if (programId) {
      params = params.set(`filter[program_id]`, programId.toString());
    }
    return this.http.get<Program[]>(`${environment.api}/admin/program`, { params });
  }

  /**
   * Convert FilterProgram to QueryParam
   */
  private _createQueryParams(filters: Partial<FilterProgram>): HttpParams {
    let params = new HttpParams();
    if (!!filters) {
      Object.keys(filters).forEach(key => {
        const value = filters[key];
        if (!!value) {
          // Allow encoding for special chars (like `+`)
          if (key === "dispositif") {
            value.forEach((item, i) => {
              params = params.set(`filter[${key}][${i}]`, encodeURIComponent(item) );
            });
          } else if (Array.isArray(value)) {
            value.forEach((item, i) => {
              params = params.set(`filter[${key}][${i}]`, item );
            });
          } else if (key === "delivery_date" || key === "actability_date") {
            params = params.set(`filter[${key}]`, this._convertToDate(filters[key]));
          } else {
            params = params.set(`filter[${key}]`, filters[key]);
          }
        }
      });
    }
    return params;
  }

  private _convertToDate(value: string): string {
    const date = new Date();
    let newDate: Date = null;
    if (value === LotDurationsEnum.NOW) {
      newDate = new Date();
    } else if (value === LotDurationsEnum.LESS6MONTHS) {
      newDate = new Date(date.setMonth(date.getMonth() + 6));
    } else if (value === LotDurationsEnum.LESS12MONTHS) {
      newDate = new Date(date.setMonth(date.getMonth() + 12));
    } else if (value === LotDurationsEnum.LESS24MONTHS) {
      newDate = new Date(date.setMonth(date.getMonth() + 24));
    }
    if (!!newDate) {
      return newDate.toISOString();
    }
    return null;
  }

  /**
   * Get blob for display image
   */
  getImage$(idFileApp: number, asDataUri: boolean = false): Observable<string> {
    return this.http
      .get(`${environment.api}/file/program/${idFileApp}`, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        switchMap(res => {
          if (asDataUri) {
            return this.getDataUriFromBlob(res.body);
          } else {
            const urlCreator = window.URL || window.webkitURL;
            return of(urlCreator.createObjectURL(res.body));
          }
        })
      );
  }

  private getDataUriFromBlob(blob: Blob): Observable<string> {
    return Observable.create(obs => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error("`blob` must be an instance of File or Blob."));
        return;
      }
      const reader = new FileReader();
      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => {
        obs.next(reader.result);
      };
      reader.onloadend = () => obs.complete();
      return reader.readAsDataURL(blob);
    });
  }

  /**
   * Add files in program
   */
   csvUpdateByAdmin$(file: File): Observable<any> {
    const params = new FormData();
    params.append("file", file, file.name);
    return this.http.post<File>(`${environment.api}/admin/program/update-file`, params);
  }

  getLastCsvUpdateFileByAdmin$(): Observable<Blob> {
    return this.http.get(`${environment.api}/admin/program/update-file`, {responseType: "blob"});
      // {responseType: "blob", observe: "response"})
      // .pipe(map((response) => {
      //   let filename = response.headers.get('content-disposition').split('filename=')[1] || '';
      //   filename = filename.split(';')[0].trim();
      //   console.log(filename);
      //   return new File([response.body as Blob], filename, { type: 'text/csv' });
      // }));
  }

  getLastCsvUpdateFileInfoByAdmin$(): Observable<{exists: boolean}> {
    return this.http.get<{exists: boolean}>(`${environment.api}/admin/program/update-file/info`);
  }

  importXmlFileByAdmin$(): Observable<any> {
     return this.http.get(`${environment.api}/admin/program/import-file`);
  }

  syncSpoByAdmin$(): Observable<any> {
     return this.http.get(`${environment.api}/admin/program/sync-spo`);
  }

  getProgramDispositifsByAdmin$(programId: number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api}/admin/program/${programId}/dispositifs`);
  }
}
