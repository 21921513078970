import { Action } from "./Action";
import { Favorite } from "./Favorite";
import { FileApp } from "./FileApp";
import { Partner } from "./Partner";
import { Program } from "./Program";

export enum LotStatusEnum {
  FREE = "Libre",
  OPTION = "Option",
  BOOKED = "Réservé",
  SOLD = "Vendu",
  DELIVERED = "Livré",
}

export enum LotExpositionsEnum {
  N = "n",
  S = "s",
  O = "o",
  E = "e",
}

export enum LotDispositifsEnum {
  LIBRE = "LIBRE",
  APM = "APM",
  PSLA = "PSLA",
  ACCESSION = "ACCESSION",
  TERTIAIRE = "TERTIAIRE",
  INTERNE = "INTERNE",
  GERE = "GERE",
  REHAB = "REHAB",
  LMNP = "LMNP",
  COLIVING = "LIBRE COLIVING",
  NUEPRO = "NUE PRO",
  SENIOR = "LIBRE SENIOR",
  ETUDIANT = "LIBRE ETUDIANT",
  TAB = "TAB",
  LMNP_ETUDIANT = "LMNP / ETUDIANT",
  LMNP_SENIOR = "LMNP / SENIOR",
  LMNP_COLIVING = "LMNP / COLIVING",
  MALRAUX = "MALRAUX",
  MH = "MH",
  FONCIER = "DEF FONCIER",
  BRS = "BRS",
  ANRU_NPNRU = "TVA 5,5%",
  PINEL_PLUS = "LIBRE PINEL +",
}

export enum LotDurationsEnum {
  NOW = "now",
  LESS6MONTHS = "lessThan6Months",
  LESS12MONTHS = "lessThan12Months",
  LESS24MONTHS = "lessThan24Months",
}

export enum LotAnnexesParkingEnum {
  PARKING_OUTSIDE = "Pk-ext.",
  PARKING_SS = "Pk-ss.",
  GARAGE = "Garage",
}

export interface Lot {
  id: number;
  label: string;
  type: string;
  nature: string;
  rooms: number;
  surface: number;
  dispositif: LotDispositifsEnum;
  status: string;
  exposition: string;
  priceGestionTTC: number;
  priceSale: number;
  priceSaleTTC: number;
  priceCommercial: number;
  priceCommercialTTC: number;
  pinelMax: number;
  floor: number;
  dateDelivery: Date;
  dateActability: Date;
  building: string;
  tva: number;
  annexes: Lot[];
  canOption: boolean;
  surfaceBalcon: number;
  surfaceGarden: number;
  surfaceTerrasse: number;
  rentProfit: number;
  boost: boolean;
  priceParam1HT: number;
  priceParam1TTC: number;
  reservationCount?: number;
  allotedPartner?: Pick<Partner, "id" | "name">;
  assignedPartners?: Pick<Partner, "id" | "name">[];
  program?: Partial<Program>;
  program_id?: number;
  actions?: Action[];
  favorites?: Favorite[];
  files: FileApp[];
  rent: number;
  planExists: boolean;
}
