import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserRoles } from "../models/User";
import { AuthState } from "../states/auth.state";

@Injectable({
  providedIn: "root",
})
export class RoleGuard  {
  constructor(private store: Store) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const role = next.data.role as UserRoles;
    return this.store.select(AuthState.hasRights).pipe(map(res => res(role)));
  }
}
