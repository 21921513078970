import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { forkJoin, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { FileApp, FileAppTypeEnum } from "../models/FileApp";
import { Program } from "../models/Program";
import { LotsService } from "../services/lots.service";
import { ProgramsService } from "../services/programs.service";

export class ProgramStateModel {
  program: Program;
}

export class FeedProgram {
  static readonly type = "[Program] Feed program by id";
  constructor(public id: number) {}
}

export class UpdateProgram {
  static readonly type = "[Program] Update program";
  constructor(public programPartial: Partial<Program>, public save = false) {}
}

@State<ProgramStateModel>({
  name: "program",
})
@Injectable()
export class ProgramState {
  @Selector()
  static program(state: ProgramStateModel): Program {
    return state.program;
  }

  @Selector()
  static documents(state: ProgramStateModel): FileApp[] {
    return state.program?.files?.filter(file => file.type !== FileAppTypeEnum.IMAGE);
  }

  @Selector()
  static images(state: ProgramStateModel): FileApp[] {
    return state.program?.files?.filter(
      file => file.type === FileAppTypeEnum.IMAGE || file.type === FileAppTypeEnum.LOGO
    );
  }

  constructor(private programsService: ProgramsService, private lotsService: LotsService) {}

  @Action(FeedProgram)
  get({ patchState }: StateContext<ProgramStateModel>, { id }: FeedProgram): Observable<any> {
    return forkJoin([this.programsService.getByAdmin$(id), this.programsService.getFilesByAdmin$(id)]).pipe(
      tap(datas => {
        patchState({ program: { ...datas[0], files: datas[1] } });
      })
    );
  }

  @Action(UpdateProgram)
  update(
    { getState, patchState }: StateContext<ProgramStateModel>,
    { programPartial, save }: UpdateProgram
  ): Observable<any> {
    if (save) {
      return this.programsService.updateByAdmin$(programPartial.id, programPartial).pipe(
        tap(programUpdated => {
          patchState({ program: { ...programUpdated, files: getState().program?.files } });
        })
      );
    }
    patchState({ program: { ...getState().program, ...programPartial } });
    return;
  }
}
