import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import jwt_decode from "jwt-decode";
import { Observable } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { Partner } from "../models/Partner";
import { User, UserRoles } from "../models/User";
import { AlertsService } from "../services/alerts.service";
import { AuthService } from "../services/auth.service";
import { ProfileService } from "../services/profile.service";
import { UsersService } from "../services/users.service";

export class AuthStateModel {
  token?: string;
  refreshToken?: string;
  user?: User;
  // Stockage d'identifiants Admin en cas de connexion en tant que
  tokenAdmin?: string;
  refreshTokenAdmin?: string;
  userAdmin?: User;
  alerts?: number;
}

export class Signin {
  static readonly type = "[Auth] Signin";
  constructor(public email: string, public password: string) {}
}

export class SigninAs {
  static readonly type = "[Auth] SigninAs";
  constructor(public id: number) {}
}

export class SigninSso {
  static readonly type = "[Auth] SigninSso";
  constructor(public token: string) {}
}

export class Signout {
  static readonly type = "[Auth] Signout";
}

export class SignoutAs {
  static readonly type = "[Auth] SignoutAs";
}

export class RefreshToken {
  static readonly type = "[Auth] Refresh token";
  constructor() {}
}

export class LoadProfile {
  static readonly type = "[Auth] Load profile";
  constructor() {}
}

export class UpdateProfile {
  static readonly type = "[Auth] Update profile";
  constructor(public user: Partial<User>) {}
}

export class UpdateNotificationCount {
  static readonly type = "[Auth] Update notification count";
}

@State<AuthStateModel>({
  name: "auth",
})
@Injectable()
export class AuthState {
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return state.token && Date.now() < jwt_decode(state.token).exp * 1000;
  }

  @Selector()
  static user(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static partner(state: AuthStateModel): Partner {
    return state.user?.partner;
  }

  @Selector()
  static userAdmin(state: AuthStateModel): User {
    return state.tokenAdmin && state.userAdmin ? state.userAdmin : null;
  }

  @Selector()
  static isLoggedAs(state: AuthStateModel): boolean {
    return !!state.tokenAdmin;
  }

  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string {
    return state.refreshToken;
  }

  @Selector()
  static roles(state: AuthStateModel): UserRoles[] {
    return state.user?.roles;
  }

  @Selector()
  static hasRights(state: AuthStateModel): (type: string) => boolean {
    return (role: UserRoles) => !!state.user && state.user.roles.indexOf(role) > -1;
  }

  @Selector()
  static isClient(state: AuthStateModel): boolean {
    return state.user?.roles.includes(UserRoles.CLIENT);
  }

  @Selector()
  static isDealer(state: AuthStateModel): boolean {
    return state.user?.roles.includes(UserRoles.DEALER);
  }

  @Selector()
  static isManager(state: AuthStateModel): boolean {
    return state.user?.roles.includes(UserRoles.MANAGER);
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return state.user?.roles.includes(UserRoles.ADMIN);
  }

  @Selector()
  static isSuperAdmin(state: AuthStateModel): boolean {
    return state.user?.roles.includes(UserRoles.SUPER_ADMIN);
  }

  @Selector()
  static alerts(state: AuthStateModel): number {
    return state.alerts ?? 0;
  }

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    private usersService: UsersService,
    private alertsService: AlertsService
  ) {}

  @Action(Signin)
  login({ patchState }: StateContext<AuthStateModel>, { email, password }: Signin): Observable<any> {
    return this.authService.authenticate$(email, password).pipe(
      tap(res => {
        patchState({
          token: res.token,
          refreshToken: res.refreshToken,
        });
      }),
      map((res: { token: string; refreshToken: string }) => {
        return { user: this.getUserFromToken(res.token), token: res.token, refreshToken: res.refreshToken };
      }),
      switchMap(datas =>
        this.profileService.get$().pipe(
          map(profile => {
            return { user: datas.user, token: datas.token, refreshToken: datas.refreshToken, profile };
          })
        )
      ),
      tap(datas =>
        patchState({
          user: { ...datas.user, ...datas.profile, roles: datas.user.roles },
          token: datas.token,
          refreshToken: datas.refreshToken,
        })
      )
    );
  }

  @Action(SigninAs)
  loginAs({ getState, patchState }: StateContext<AuthStateModel>, { id }: SigninAs): Observable<any> {
    return this.usersService.logAs$(id).pipe(
      tap(res => {
        const oldToken = getState().token;
        const oldRefreshToken = getState().refreshToken;
        const oldUser = getState().user;
        patchState({
          token: res.token,
          refreshToken: res.refreshToken,
          tokenAdmin: oldToken,
          refreshTokenAdmin: oldRefreshToken,
          userAdmin: oldUser,
        });
      }),
      map((res: { token: string; refreshToken: string }) => {
        return { user: this.getUserFromToken(res.token), token: res.token, refreshToken: res.refreshToken };
      }),
      switchMap(datas =>
        this.profileService.get$().pipe(
          map(profile => {
            return { user: datas.user, token: datas.token, refreshToken: datas.refreshToken, profile };
          })
        )
      ),
      tap(datas => {
        patchState({
          user: { ...datas.user, ...datas.profile, roles: datas.user.roles },
          token: datas.token,
          refreshToken: datas.refreshToken,
        });
      })
    );
  }

  @Action(SigninSso)
  sso({ getState, patchState }: StateContext<AuthStateModel>, { token }: SigninSso): Observable<any> {
    return this.authService.authenticateSso$(token).pipe(
      tap((res: { token: string; refreshToken: string; redirect: string }) => {
        const user = this.getUserFromToken(res.token);
        patchState({
          token: res.token,
          refreshToken: res.refreshToken,
          user,
        });
        if (res.redirect === "change_password") {
          this.router.navigate(["activate"]);
        }
      }),
      switchMap(() => this.profileService.get$()),
      tap(profile => patchState({ user: { ...getState().user, ...profile, roles: getState().user.roles } }))
    );
  }

  @Action(Signout)
  logout({ setState }: StateContext<AuthStateModel>): void {
    setState({});
  }

  @Action(SignoutAs)
  logoutAs({ getState, setState, patchState }: StateContext<AuthStateModel>): Observable<any> {
    const adminToken = getState().tokenAdmin;
    const adminRefreshToken = getState().refreshTokenAdmin;
    const adminData = getState().userAdmin;
    setState({});
    if (!!adminToken && !!adminRefreshToken && !!adminData) {
      patchState({
        token: adminToken,
        refreshToken: adminRefreshToken,
        user: adminData,
      });
    }
    const user = this.getUserFromToken(adminToken);
    return this.profileService.get$().pipe(
      map(profile => {
        return { user, token: adminToken, refreshToken: adminRefreshToken, profile };
      }),
      tap(datas => {
        patchState({
          user: { ...datas.user, ...datas.profile, roles: datas.user.roles },
          token: datas.token,
          refreshToken: datas.refreshToken,
        });
      })
    );
  }

  @Action(RefreshToken)
  refreshToken({ getState, patchState }: StateContext<AuthStateModel>): Observable<any> {
    console.log("ACTION REFRESH");
    const refreshToken = getState().refreshToken;
    return this.authService.refreshToken$(refreshToken).pipe(
      tap((res: { token: string; refreshToken: string }) => {
        console.log("Retour du service", res);
        patchState({
          token: res.token,
          refreshToken: res.refreshToken,
        });
      })
    );
  }

  @Action(LoadProfile)
  loadProfile({ getState, patchState }: StateContext<AuthStateModel>): Observable<any> {
    const token = getState().token;
    if (this._isTokenValid(token)) {
      return this.profileService
        .get$()
        .pipe(tap(profile => patchState({ user: { ...getState().user, ...profile, roles: getState().user.roles } })));
    }
  }

  @Action(UpdateProfile)
  updateProfile({ getState, patchState }: StateContext<AuthStateModel>, { user }: UpdateProfile): Observable<any> {
    const u = {
      email: user.email,
      civility: user.civility,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      address: user.address,
      alertEmail: user.alertEmail,
      alertSms: user.alertSms,
    };
    return this.profileService
      .update$(u)
      .pipe(tap(profile => patchState({ user: { ...getState().user, ...profile, roles: getState().user.roles } })));
  }

  @Action(UpdateNotificationCount)
  updateNotificationCount({ patchState }: StateContext<AuthStateModel>): Observable<number> {
    return this.alertsService.count$().pipe(tap(count => patchState({ alerts: count })));
  }

  private getUserFromToken(token: string): User {
    if (token) {
      const data = this._decodeToken(token);
      return {
        email: data.username,
        roles: this.addRoles(data.roles),
      };
    }
    return null;
  }

  /**
   * Réattribution des rôles
   * FIXME A voir si le comportement correspond bien au retour du back
   */
  private addRoles(role: UserRoles[]): UserRoles[] {
    let roles: UserRoles[] = [];
    switch (role[0]) {
      case UserRoles.SUPER_ADMIN:
        roles = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.DEALER];
        break;
      case UserRoles.ADMIN:
        roles = [UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.DEALER];
        break;
      case UserRoles.MANAGER:
        roles = [UserRoles.MANAGER, UserRoles.DEALER];
        break;
      case UserRoles.DEALER:
        roles = [UserRoles.DEALER];
        break;
      case UserRoles.CLIENT:
        roles = [UserRoles.CLIENT];
        break;
      default:
        break;
    }
    return roles;
  }

  private _isTokenValid(token: string): boolean {
    return token && Date.now() < this._decodeToken(token).exp * 1000;
  }

  private _decodeToken(token: string): any {
    if (token) {
      return jwt_decode(token);
    }
    return null;
  }
}
