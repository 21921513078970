import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { compose, insertItem, patch, removeItem } from "@ngxs/store/operators";
import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";
import { Lot } from "../models/Lot";
import { FavoritesService } from "../services/favorites.service";

export class SettingsStateModel {
  favorites: string[];
  filterFavorites: string[];
}

export class GetAllFavorites {
  static readonly type = "[Settings] Get all";
  constructor() {}
}

export class UpdateFavoritesFilter {
  static readonly type = "[Settings] Update favorites filter";
  constructor(public filterFavorites: string[]) {}
}

@State<SettingsStateModel>({
  name: "settings",
  defaults: {
    favorites: [],
    filterFavorites: [],
  },
})
@Injectable()
export class SettingsState {
  @Selector()
  static favorites(state: SettingsStateModel): string[] {
    return state.favorites.sort();
  }

  @Selector()
  static favoritesSelected(state: SettingsStateModel): string[] {
    return state.filterFavorites;
  }

  constructor(private favoritesService: FavoritesService) {}

  @Action(GetAllFavorites)
  getAll({ patchState }: StateContext<SettingsStateModel>): Observable<any> {
    return this.favoritesService.getAllLabels$().pipe(
      take(1),
      tap(all => patchState({ favorites: all }))
    );
  }

  @Action(UpdateFavoritesFilter)
  updateFilter({ patchState }: StateContext<SettingsStateModel>, { filterFavorites }: UpdateFavoritesFilter): void {
    patchState({ filterFavorites });
  }
}
