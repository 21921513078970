<div [class.menu-opened]="isOpen" class="header-mobile-default">
  <mat-toolbar *transloco="let t; read: 'header'">
    <div class="col-4">
      <button mat-fab extended class="menu-mobile-toggle" (click)="isOpen = !isOpen">
        <span class="burger-bar first"></span>
        <span class="burger-bar second"></span>
        <span class="burger-bar third"></span>
        <!-- <i [ngClass]="{ 'icon-close': isOpen, 'icon-arrow-down': !isOpen }"></i> -->
      </button>
    </div>
    <div class="col-4 logo">
      <img src="/assets/images/logo_my_sporting_white.png" />
    </div>
    <div class="col-4 profile">
      <ng-content select="[profile]"></ng-content>
    </div>
  </mat-toolbar>

  <div class="menu-mobile-links">
    <ng-content select="[menuList]"></ng-content>
  </div>
</div>
