import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutLoggedComponent } from "./layout-logged.component";
import { HeaderModule } from "../header/header.module";
import { FooterModule } from "../footer/footer.module";
import { RouterModule } from "@angular/router";
import { SubheaderModule } from "../subheader/subheader.module";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LinkFloatModule } from "../link-float/link-float.module";

@NgModule({
  declarations: [LayoutLoggedComponent],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    SubheaderModule,
    FooterModule,
    MatButtonModule,
    BrowserAnimationsModule,
    LinkFloatModule,
  ],
})
export class LayoutLoggedModule {}
