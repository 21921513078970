import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState, Signout } from "../states/auth.state";
import { Store } from "@ngxs/store";
@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(private store: Store, private router: Router) {}

  canActivate(): boolean {
    if (!this.store.selectSnapshot(AuthState.isAuthenticated)) {
      this.store.dispatch(new Signout());
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
  }
}
