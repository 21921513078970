import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { FilterProgram } from "../models/FilterProgram";
import { Program } from "../models/Program";
import { ProgramsService } from "../services/programs.service";

export class ProgramsStateModel {
  programs: Program[];
  programsLabels: string[];
  programsOpen: number[];
  filters: Partial<FilterProgram>;
  showMoreCriterias: boolean;
}

export class SearchPrograms {
  static readonly type = "[Programs] Search programs";
  constructor(public filters: Partial<FilterProgram>) {}
}

export class UpdateFilters {
  static readonly type = "[Programs] Update filters";
  constructor(public filters: Partial<FilterProgram>) {}
}

export class ResetState {
  static readonly type = "[Programs] Reset state";
}

export class ToggleOpenProgram {
  static readonly type = "[Programs] Open or close a program to display lots";
  constructor(public program: Program) {}
}

export class ToggleFiltersAdvanced {
  static readonly type = "[Programs] Toggle filters advanced";
  constructor(public isOpen: boolean) {}
}

export class IncrementFavorites {
  static readonly type = "[Program] Increment count favorite in program";
  constructor(public program: Program) {}
}

export class DecrementFavorites {
  static readonly type = "[Program] Decrement count favorite in program";
  constructor(public program: Program) {}
}

@State<ProgramsStateModel>({
  name: "programs",
  defaults: {
    programs: undefined,
    programsLabels: undefined,
    programsOpen: [],
    filters: null,
    showMoreCriterias: false,
  },
})
@Injectable()
export class ProgramsState {
  @Selector()
  static programs(state: ProgramsStateModel): Program[] {
    return state.programs;
  }

  @Selector()
  static programsLabels(state: ProgramsStateModel): string[] {
    return state.programsLabels;
  }

  @Selector()
  static programsBoosted(state: ProgramsStateModel): Program[] {
    return state.programs.filter(p => !!p.boostCount) ?? [];
  }

  @Selector()
  static programsWithFavorites(state: ProgramsStateModel): Program[] {
    return state.programs.filter(p => !!p.favoriteCount) ?? [];
  }

  @Selector()
  static programsOpen(state: ProgramsStateModel): number[] {
    return state.programsOpen;
  }

  // @Selector()
  // static programsBoostedOpen(state: ProgramsStateModel): number[] {
  //   const programsOpen = state.programsOpen;
  //   return state.programs
  //     .filter(p => p.boostCount && p.boostCount > 0 && programsOpen.indexOf(p.id) > -1)
  //     .map(p => p.id);
  // }

  // @Selector()
  // static programsFavoritesOpen(state: ProgramsStateModel): number[] {
  //   const programsOpen = state.programsOpen;
  //   return state.programs
  //     .filter(p => p.favoriteCount && p.favoriteCount > 0 && programsOpen.indexOf(p.id) > -1)
  //     .map(p => p.id);
  // }

  @Selector()
  static filters(state: ProgramsStateModel): Partial<FilterProgram> {
    return state.filters;
  }

  @Selector()
  static showMoreCriterias(state: ProgramsStateModel): boolean {
    return state.showMoreCriterias;
  }

  @Selector()
  static counters(state: ProgramsStateModel): { lots: number; boost: number; favorites: number } {
    let lots = 0;
    let boost = 0;
    let favorites = 0;
    const programs = state.programs;
    if (programs) {
      lots = programs.reduce((sum, program) => sum + program.lotCount, 0);
      boost = programs.reduce((sum, program) => sum + program.boostCount, 0);
      favorites = programs.reduce((sum, program) => sum + program.favoriteCount, 0);
    }
    return { lots, boost, favorites };
  }

  constructor(private programsService: ProgramsService) {}

  @Action(SearchPrograms)
  search({ patchState }: StateContext<ProgramsStateModel>, { filters }: SearchPrograms): Observable<any> {
    return this.programsService.search$(filters).pipe(
      tap(programs => {
        const programsLabels: string[] = [];
        if (Array.isArray(programs)) {
          programs.forEach(p => {
            if (Array.isArray(p.favoriteLabels)) {
              p.favoriteLabels.forEach(l => {
                if (programsLabels.indexOf(l) === -1) {
                  programsLabels.push(l);
                }
              });
            }
          });
        }
        patchState({
          programs,
          programsLabels,
        });
      })
    );
  }

  @Action(UpdateFilters)
  updateFilters(ctx: StateContext<ProgramsStateModel>, { filters }: UpdateFilters): void {
    let res = { ...filters };
    res = this._convertStringToArray(res, "type");
    res = this._convertStringToArray(res, "lot_statuses");
    res = this._convertStringToArray(res, "nature");
    res = this._convertStringToArray(res, "dispositif");
    res = this._convertStringToArray(res, "exposition");
    res = this._convertStringToArray(res, "floor");
    res = this._convertStringToArray(res, "annexes");
    ctx.patchState({ filters: { ...res, price_min: +res.price_min ?? 0, price_max: +res.price_max ?? 500000 } });
  }

  @Action(ResetState)
  reset({ setState }: StateContext<ProgramsStateModel>): void {
    setState({
      programs: [],
      programsLabels: [],
      programsOpen: [],
      filters: null,
      showMoreCriterias: false,
    });
  }

  @Action(ToggleOpenProgram)
  toggleOpenProgram(ctx: StateContext<ProgramsStateModel>, { program }: ToggleOpenProgram): void {
    const programsOpen = ctx.getState().programsOpen;
    const index = programsOpen.findIndex(po => po === program.id);
    if (index > -1) {
      ctx.setState(
        patch({
          programsOpen: removeItem<number>(po => po === program.id),
        })
      );
    } else {
      ctx.setState(
        patch({
          programsOpen: append<number>([program.id]),
        })
      );
    }
  }

  @Action(ToggleFiltersAdvanced)
  toggleFiltersAdvanced({ patchState }: StateContext<ProgramsStateModel>, { isOpen }: ToggleFiltersAdvanced): void {
    patchState({ showMoreCriterias: isOpen });
  }

  @Action(IncrementFavorites)
  incrementFavorites(ctx: StateContext<ProgramsStateModel>, { program }: IncrementFavorites): void {
    ctx.setState(
      patch({
        programs: updateItem<Program>(p => p.id === program.id, {
          ...program,
          favoriteCount: program.favoriteCount + 1,
        }),
      })
    );
  }

  @Action(DecrementFavorites)
  decrementFavorites(ctx: StateContext<ProgramsStateModel>, { program }: DecrementFavorites): void {
    ctx.setState(
      patch({
        programs: updateItem<Program>(p => p.id === program.id, {
          ...program,
          favoriteCount: program.favoriteCount - 1,
        }),
      })
    );
  }

  private _convertStringToArray(filters: Partial<FilterProgram>, key: string): Partial<FilterProgram> {
    if (!!filters[key] && !Array.isArray(filters[key])) {
      filters[key] = [filters[key]];
    }
    return filters;
  }
}
